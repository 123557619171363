<!-- 申请退款 -->
<template>
	<div :class="themeClass" class="min-width1100 margin-t-20">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="flex-colum border-F2F2F2">
			<div class="evaluate-title font-size16 font-weight700 padding-lr-15">
				申请批量退款
			</div>
      <div class="form-box padding-15">
				<el-form ref="form" :model="form" :rules="rules" label-width="90px">
					<!-- <el-form-item label="总退款金额">
						<el-input v-model="form.fRefundMoney" disabled></el-input>
					</el-form-item> -->
					<el-form-item label="退款原因" prop="reason">
						<el-select v-model="form.reason" placeholder="请选择退款原因">
							<el-option v-for="(item,index) in refundRemark" :key="index" :label="item" :value="item"></el-option>
						</el-select>
						<!-- <el-input v-model="form.reason"></el-input> -->
					</el-form-item>
				</el-form>
			</div>
      <el-table
      ref="multipleTable"
      v-loading="tableLoading"
      :data="tableData"
      highlight-current-row
      border
      :row-class-name="tableRowClassName"
      :header-cell-style="{ 'text-align': 'center', background: '#F7F9FE' }"
      @selection-change="changeCheckBoxRow"
      @select-all="changeCheckBoxAllRow"
    >
        	<!-- <el-table-column width="50" >
					<template scope="scope">
						<el-checkbox v-model="scope.$index == radioRow"
							@change="getrowindex(scope.$index,scope)"></el-checkbox>
					</template>
				</el-table-column> -->
        <el-table-column type="selection" width="55" align="center" :selectable='selectEnable' fixed />
        <el-table-column fixed type="index" width="50" align="center"  label="序号" />
        <el-table-column
          v-for="(item, index) in tableLabel"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          show-overflow-tooltip
          :fixed="item.fixed"
          :min-width="item.width"
          :align="item.align"
        >
          <template slot-scope="scope">
            <template
              v-if="item.prop === 'imageUrl'"
            >
            <div class="img-box radius10 flex-row-center-center">
              <img :src="scope.row[item.prop]" alt="" class="img-style">
            </div>
            </template>
            <template v-else-if="item.prop === 'fBatchRefundResult'">
              <div style="color:#FF0000">{{ scope.row[item.prop] }}</div>
            </template>
            <template v-else-if="item.prop === 'fBatchRefundStatus'">
              <div v-if="scope.row[item.prop] == 0" style="color:#FF0000">否</div>
              <div v-if="scope.row[item.prop] == 1">是</div>
            </template>
            <template v-else-if="item.prop === 'fGatheringMoney' || item.prop === 'fPlanAmount' || item.prop === 'fRefundMoney' || item.prop === 'fRefundGoodsMoney' || item.prop === 'fRefundFMoney' || item.prop === 'fRefundPoint'">
              {{ scope.row[item.prop] | NumFormat }}
            </template>
            <template v-else>
              {{ scope.row[item.prop] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
			<div class="padding-lr-15 margin-b-15 margin-t-15">
				<div class="pointer evaluate-btn text-center background-color-theme " @click="submitClick">
					提交申请
				</div>
			</div>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="20%" class="dialog">
			<div class="flex-colum font-size13">
				<!-- <div class="margin-b-5">优惠券：不退</div> -->
        <div v-for="(item,index) in modelContent" :key="index">
          <div class="margin-b-5">实退金额：¥{{item.fRefundMoney}}</div>
          <div v-if="item.fIsShowFTMoney == 1" class="margin-b-5">实退货款金额：¥{{item.fRefundGoodsMoney}}</div>
          <div v-if="item.fIsShowFTMoney == 1" class="margin-b-5">实退运费金额：¥{{item.fRefundFMoney}}</div>
          <div class="margin-b-5">实退积分：{{item.fRefundPoint}}个<span v-if='item.fRemark == 1' style="color:red">（跨年订单积分不退回）</span></div>
          <div>{{item.fDispRefundPath}}</div>
        </div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue'
	import UploadImgList from "@/components/upload/UploadImgList.vue";
	export default {
		components: {
			breadcrumb,
			UploadImgList
		},
		data() {
			return {
        tableLoading: false,
        selectionList: [],
        tableData: [],
        tableLabel: [
          {
            label: '是否可退款',
            width: '100',
            prop: 'fBatchRefundStatus',
            align: 'center',
            fixed: false
          },
          {
            label: '订单号',
            width: '130',
            prop: 'fOrderNumber',
            align: 'left',
            fixed: false
          },
          {
            label: '商品',
            width: '220',
            prop: 'fGoodsFullName',
            align: 'left',
            fixed: false
          },
          // {
          //   label: '图片',
          //   width: '120',
          //   prop: 'imageUrl',
          //   align: 'left',
          //   fixed: false
          // },
          {
            label: '店铺',
            width: '120',
            prop: 'fShopName',
            align: 'left',
            fixed: false
          },
          {
            label: '提货方式',
            width: '80',
            prop: 'fDelivery',
            align: 'center',
            fixed: false
          },
          {
            label: '数量',
            width: '80',
            prop: 'fPlanAmount',
            align: 'right',
            fixed: false
          },
          {
            label: '单位',
            width: '80',
            prop: 'fUnitName',
            align: 'left',
            fixed: false
          },
          // {
          //   label: '金额',
          //   width: '80',
          //   prop: 'fGatheringMoney',
          //   align: 'right',
          //   fixed: false
          // },
          
          
          {
            label: '实退货款金额',
            width: '120',
            prop: 'fRefundGoodsMoney',
            align: 'right',
            fixed: false
          },
          {
            label: '实退运费金额',
            width: '120',
            prop: 'fRefundFMoney',
            align: 'right',
            fixed: false
          },
          {
            label: '实退总金额',
            width: '100',
            prop: 'fRefundMoney',
            align: 'right',
            fixed: false
          },
          {
            label: '实退积分',
            width: '120',
            prop: 'fRefundPoint',
            align: 'right',
            fixed: false
          },
          {
            label: '退款账户',
            width: '120',
            prop: 'fDispRefundPath',
            align: 'left',
            fixed: false
          },
          {
            label: '不可退款原因',
            width: '220',
            prop: 'fBatchRefundResult',
            align: 'left',
            fixed: false
          },
        ],
				dialogVisible: false,
				modelContent: [],
				rules: {
					reason: [{
						required: true,
						message: "退款原因不能为空",
						trigger: "blur,change",
					}]
				},
				form: {
					// fRefundMoney: 0,
					reason: ''
				},
				refundRemark: []
			}
		},
		 mounted() {
			let ids = this.$store.getters.getCommonData;
        this.getOrderDetails(ids)
      this.getRefundRemark()
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
    // 表格斑马线
    tableRowClassName(rowIndex) {
      if (rowIndex.rowIndex % 2 === 0) {
        return 'warning-row'
      } else if (rowIndex.rowIndex % 2 === 1) {
        return 'success-row'
      }
    },
    // // 禁用复选框
    selectEnable(row, rowIndex) {
      if (row.fBatchRefundStatus == 0 || row.fOrderNumber == "合计") {
        return false
      } else {
        return true
      }
    },
    // 行复选框选中事件
    changeCheckBoxRow(selection) {
      this.selectionList = selection
      console.log(this.selectionList)
      let sumfPlanAmount = 0
      let sumfGatheringMoney = 0
      let sumfRefundMoney = 0
      let sumfRefundGoodsMoney = 0
      let sumfRefundFMoney = 0
      let sumfRefundPoint = 0
      this.selectionList.forEach(item => {
        sumfPlanAmount = sumfPlanAmount + item.fPlanAmount
        sumfGatheringMoney = sumfGatheringMoney + item.fGatheringMoney
        sumfRefundMoney = sumfRefundMoney + item.fRefundMoney
        sumfRefundGoodsMoney = sumfRefundGoodsMoney + item.fRefundGoodsMoney
        sumfRefundFMoney = sumfRefundFMoney + item.fRefundFMoney
        sumfRefundPoint = sumfRefundPoint + item.fRefundPoint
      })
      let obj = {
        fOrderNumber: '合计',
        fPlanAmount: sumfPlanAmount,
        fGatheringMoney: sumfGatheringMoney,
        fRefundMoney: sumfRefundMoney,
        fRefundGoodsMoney: sumfRefundGoodsMoney,
        fRefundFMoney: sumfRefundFMoney,
        fRefundPoint: sumfRefundPoint,
      }
      console.log(obj)
            if (this.tableData.length > 0) {
              this.tableData.pop()
              this.tableData.push(obj)
            }
      console.log(selection, '你看看')
    },
    // 复选框全部选中事件
    changeCheckBoxAllRow(selection) {
      // console.log('selection', selection)
      this.selectionList = selection
    },
			// 提交退款
			submitClick() {
				this.$refs["form"].validate((valid) => {
					if (!valid) {
						return;
					}
          let arrs = this.tableData.filter(item => {
            return item.fBatchRefundStatus === 1
          })
          if (arrs.length == 0) {
            this.$message.warning('暂无可退款订单')
            return
          }
          if (this.selectionList.length == 0) {
            this.$message.warning('请选择批量退款订单')
            return
          }
          console.log(this.selectionList)

          this.$confirm('您确定对这些订单进行退款吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submit()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
					// this.dialogVisible = true;
				})
			},
			submit() {
        let that = this
        let submitFOrderIDs = []
        this.selectionList.forEach(item => {
          submitFOrderIDs.push(item.fOrderID)
        })
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/create-batch-order-refund-bill", {
					fOrderIDs: submitFOrderIDs,
					fRemark: this.form.reason,
					fAppTypeID: this.fAppTypeID
				}).then(res => {
          console.log(res)
          if (res.obj.status === true) {
            this.$message.success(res.obj.message)
          }else {
            this.$message.warning(res.obj.message)
          }
					// if (res.obj.status == true) {
					// 	this.dialogVisible = false;
					// 	this.$message('退款申请已提交');
					// } else {
					// 	this.$message(res.obj.message);
					// }
				}).catch(err => {
          console.log(err)
				})
			},
			// 获取订单信息
			getOrderDetails(item) {
        let list = []
        let sumfPlanAmount = 0
        let sumfGatheringMoney = 0
        let sumfRefundMoney = 0
        let sumfRefundGoodsMoney = 0
        let sumfRefundFMoney = 0
        let sumfRefundPoint = 0
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/computer-refund-bill-by-order-ids", {
					fOrderIDs: item,
          fAppTypeID: this.fAppTypeID
				}).then(res => {
          this.$nextTick( ()=>{
            res.obj.forEach(item => {
              if (item.fBatchRefundStatus == 1) {
                item.fDispRefundPath = item.fDispRefundPath.slice(4)
                sumfPlanAmount = sumfPlanAmount + item.fPlanAmount
                sumfGatheringMoney = sumfGatheringMoney + item.fGatheringMoney
                sumfRefundMoney = sumfRefundMoney + item.fRefundMoney
                sumfRefundGoodsMoney = sumfRefundGoodsMoney + item.fRefundGoodsMoney
                sumfRefundFMoney = sumfRefundFMoney + item.fRefundFMoney
                sumfRefundPoint = sumfRefundPoint + item.fRefundPoint
                // list.push(item)
              }
            })
            this.tableData = res.obj
            let arrs = this.tableData.filter(item => {
            return item.fBatchRefundStatus === 1
          })
          if (arrs.length == 0) {
            this.$message.warning('暂无可退款订单')
            return
          }
            // console.log(sumfPlanAmount)
            let obj = {
              fOrderNumber: '合计',
              fPlanAmount: sumfPlanAmount,
              fGatheringMoney: sumfGatheringMoney,
              fRefundMoney: sumfRefundMoney,
              fRefundGoodsMoney: sumfRefundGoodsMoney,
              fRefundFMoney: sumfRefundFMoney,
              fRefundPoint: sumfRefundPoint,
            }
            if (this.tableData.length > 0) {
              this.tableData.push(obj)
            }
            list = this.tableData.filter(item => {
              return item.fBatchRefundStatus === 1
            })
            console.log(list)
            if (list) {
              //再遍历数组，将数据放入方法中
              setTimeout(() => {
                list.forEach((row) => {
                  //   console.log(row)
                  this.$refs.multipleTable.toggleRowSelection(row,true);//点击选中
                  })
              // 我最开始尝试0，发现没效果不知道为什么，后来改成1000又感觉太长，最后改成100感觉正好
              }, 100)
            }
          })
				}).catch(err => {

				})
			},
			// 获取退款理由
			getRefundRemark() {
				this.ApiRequestPostNOMess("api/mall/ebsale/refund-bill/get-refund-remark", {}).then(res => {
					this.refundRemark = res.obj;
				}).catch(err => {

				})
			},
		}
	}
</script>

<style lang="scss" scoped>
  .img-box {
		width: 65px;
		height: 65px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    margin: 0 auto;
	}
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.bg-40C6A9 {
		background-color: #40C6A9;
	}

	.label {
		height: 16px;
		line-height: 16px;
		padding: 0 5px;
	}

	.evaluate-title {
		height: 44px;
		line-height: 44px;
	}

	.order-top {
		height: 35px;
		line-height: 35px;
	}

	.order-bottom {}

	.img-box {
		width: 65px;
		height: 65px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
	}

	.img-style {
		width: 44px;
		height: 59px;
	}

	.distributionMode-box {
		height: 16px;
		line-height: 16px;
	}

	.modeOfDelivery-box {
		height: 14px;
		line-height: 14px;
	}

	.linear-gradient {
		background-image: linear-gradient(to right, #FF0000, #FF9999);
	}

	.padding-lr-37 {
		padding-left: 37px;
		padding-right: 37px;
	}

	.bottom-right {
		height: 65px;
		// line-height: 65px;
	}

	.line-height24 {
		line-height: 24px;
	}

	.evaluate-btn {
		width: 235px;
		height: 45px;
		line-height: 45px;
	}

	.form-box::v-deep {
		.el-input {
			width: 330px !important;
		}

		.el-textarea {
			width: 330px !important;

			.el-textarea__inner {
				min-height: 67px !important;
			}
		}
	}

	.dialog::v-deep {
		.el-dialog__body {
			padding: 0 20px !important;
		}
	}
</style>
